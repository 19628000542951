import React from "react"

const RetroIcon = () => {
  return (
    <svg
      width="62"
      height="49"
      viewBox="0 0 62 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.1111 20.6991V33.0049C24.1111 33.9558 25.118 34.5152 25.9569 34.0676L40.6121 25.6214C41.4511 25.1738 41.4511 23.9432 40.6121 23.4959L25.9569 15.0496C25.118 14.6021 24.1111 15.1615 24.1111 16.1124V20.6991Z"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M31.3828 45.9259C19.3007 45.9259 9.56796 36.1373 9.56796 24.1111C9.56796 12.085 19.3567 2.2963 31.3828 2.2963"
        stroke="#66B4F9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.3826 2.2963C43.4648 2.2963 53.1974 12.085 53.1974 24.1111C53.1974 36.1373 43.4087 45.9259 31.3826 45.9259"
        stroke="#66B4F9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.27455 40.3882C4.14217 35.7458 2.2963 30.1522 2.2963 24.1111C2.2963 18.126 4.14217 12.5325 7.21861 7.8898"
        stroke="#F8B4E1"
        strokeLinecap="round"
      />
      <path
        d="M55.547 7.83388C58.6793 12.4765 60.5251 18.0701 60.5251 24.1111C60.5251 30.0962 58.6793 35.6897 55.6028 40.3324"
        stroke="#F8B4E1"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default RetroIcon
