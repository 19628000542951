import { gsap } from "gsap"
import React, { useRef } from "react"
import { useIntl } from "react-intl"

import logo from "@/assets/images/logo.svg"
import languageLogo from "@/assets/images/world-icon.png"
import PlayCircle from "@/svgs/PlayCircle/PlayCircle"

import * as styles from "./Footer.module.scss"

const Footer = () => {
  const intl = useIntl()
  const tooltip = useRef(null)

  const handleClickIconClipboard = () => {
    navigator.clipboard.writeText(
      `${intl.formatMessage({ id: "current-url" })}`
    )

    let tl = gsap.timeline()
    tl.to(tooltip.current, { display: "flex", duration: 0 })
    tl.to(tooltip.current, { opacity: 1, duration: 0.1 })
  }

  const handleClickNotice = () => {
    let tl = gsap.timeline()
    tl.to(tooltip.current, { opacity: 0, duration: 0.1 })
    tl.to(tooltip.current, { display: "none", duration: 0 })
  }

  return (
    <div className={styles.FooterComponent}>
      <div className={styles.wrapper}>
        <div className={styles.logo_wrapper}>
          <img src={logo} alt="logo Playplay" />
        </div>
        <div className={styles.description_wrapper}>
          <p>{intl.formatMessage({ id: "footer-pp-description" })}</p>
          <a
            href={intl.formatMessage({ id: "pp-url" })}
            target={"_blank"}
            rel="noreferrer"
          >
            {intl.formatMessage({ id: "footer-discover" })}
          </a>
        </div>
        <div className={styles.share_wrapper}>
          <p>{intl.formatMessage({ id: "footer-share" })}</p>
          <ul>
            <li>
              <a
                href={"https://twitter.com/intent/tweet"}
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  width="20"
                  height="17"
                  viewBox="0 0 20 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.9297 4.4375C18.7109 3.85156 19.4141 3.14844 19.9609 2.32812C19.2578 2.64062 18.4375 2.875 17.6172 2.95312C18.4766 2.44531 19.1016 1.66406 19.4141 0.6875C18.6328 1.15625 17.7344 1.50781 16.8359 1.70312C16.0547 0.882812 15 0.414062 13.8281 0.414062C11.5625 0.414062 9.72656 2.25 9.72656 4.51562C9.72656 4.82812 9.76562 5.14062 9.84375 5.45312C6.44531 5.25781 3.39844 3.61719 1.36719 1.15625C1.01562 1.74219 0.820312 2.44531 0.820312 3.22656C0.820312 4.63281 1.52344 5.88281 2.65625 6.625C1.99219 6.58594 1.32812 6.42969 0.78125 6.11719V6.15625C0.78125 8.14844 2.1875 9.78906 4.0625 10.1797C3.75 10.2578 3.35938 10.3359 3.00781 10.3359C2.73438 10.3359 2.5 10.2969 2.22656 10.2578C2.73438 11.8984 4.25781 13.0703 6.05469 13.1094C4.64844 14.2031 2.89062 14.8672 0.976562 14.8672C0.625 14.8672 0.3125 14.8281 0 14.7891C1.79688 15.9609 3.94531 16.625 6.28906 16.625C13.8281 16.625 17.9297 10.4141 17.9297 4.98438C17.9297 4.78906 17.9297 4.63281 17.9297 4.4375Z"
                    fill="white"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${intl.formatMessage(
                  { id: "current-url" }
                )}`}
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.6875 10.5C19.6875 5.14844 15.3516 0.8125 10 0.8125C4.64844 0.8125 0.3125 5.14844 0.3125 10.5C0.3125 15.3438 3.82812 19.3672 8.47656 20.0703V13.3125H6.01562V10.5H8.47656V8.39062C8.47656 5.96875 9.92188 4.60156 12.1094 4.60156C13.2031 4.60156 14.2969 4.79688 14.2969 4.79688V7.17969H13.0859C11.875 7.17969 11.4844 7.92188 11.4844 8.70312V10.5H14.1797L13.75 13.3125H11.4844V20.0703C16.1328 19.3672 19.6875 15.3438 19.6875 10.5Z"
                    fill="white"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a
                href={`http://www.linkedin.com/shareArticle?mini=true&url=${intl.formatMessage(
                  { id: "current-url" }
                )}`}
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.5 0.75H1.46094C0.796875 0.75 0.25 1.33594 0.25 2.03906V17C0.25 17.7031 0.796875 18.25 1.46094 18.25H16.5C17.1641 18.25 17.75 17.7031 17.75 17V2.03906C17.75 1.33594 17.1641 0.75 16.5 0.75ZM5.52344 15.75H2.94531V7.42969H5.52344V15.75ZM4.23438 6.25781C3.375 6.25781 2.71094 5.59375 2.71094 4.77344C2.71094 3.95312 3.375 3.25 4.23438 3.25C5.05469 3.25 5.71875 3.95312 5.71875 4.77344C5.71875 5.59375 5.05469 6.25781 4.23438 6.25781ZM15.25 15.75H12.6328V11.6875C12.6328 10.75 12.6328 9.5 11.3047 9.5C9.9375 9.5 9.74219 10.5547 9.74219 11.6484V15.75H7.16406V7.42969H9.625V8.5625H9.66406C10.0156 7.89844 10.875 7.19531 12.125 7.19531C14.7422 7.19531 15.25 8.95312 15.25 11.1797V15.75Z"
                    fill="white"
                  />
                </svg>
              </a>
            </li>
            <li>
              <button onClick={() => handleClickIconClipboard()}>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.7578 15.9297C14.7109 13.9766 14.7109 10.7344 12.7578 8.74219C12.25 8.27344 11.7031 7.92188 11.1172 7.64844C10.8828 7.57031 10.5703 7.6875 10.4922 7.96094C10.4531 8.11719 10.4141 8.39062 10.375 8.54688C10.375 8.74219 10.4922 8.9375 10.6875 9.01562C11.0781 9.17188 11.4297 9.44531 11.7422 9.75781C13.1875 11.1641 13.1875 13.5078 11.7812 14.9141L11.7422 14.9531L8.65625 18.0391C7.21094 19.4844 4.90625 19.4844 3.46094 18.0391C2.01562 16.5938 2.01562 14.2891 3.46094 12.8438C3.46094 12.8438 5.76562 10.5391 6.39062 9.91406C6.50781 9.79688 6.54688 9.64062 6.54688 9.52344C6.50781 9.32812 6.50781 9.13281 6.50781 8.9375C6.46875 8.54688 6 8.35156 5.6875 8.625C5.64844 8.66406 5.60938 8.70312 5.57031 8.74219L2.48438 11.8672C0.492188 13.8203 0.492188 17.0625 2.48438 19.0156C4.4375 21.0078 7.67969 21.0078 9.63281 19.0156L12.7578 15.9297ZM9.20312 12.2969C9.71094 12.7656 10.2578 13.1172 10.8438 13.3906C11.0781 13.4688 11.3906 13.3516 11.4688 13.0781C11.5078 12.9219 11.5469 12.6484 11.5859 12.4922C11.5859 12.2969 11.4688 12.1016 11.2734 12.0234C10.8828 11.8672 10.5312 11.5938 10.2188 11.2812C8.77344 9.875 8.77344 7.53125 10.1797 6.125L10.2188 6.08594L13.3047 3C14.75 1.55469 17.0547 1.55469 18.5 3C19.9453 4.44531 19.9453 6.75 18.5 8.19531C18.5 8.19531 16.1953 10.5 15.5703 11.125C15.4531 11.2422 15.4141 11.3984 15.4141 11.5156C15.4531 11.6719 15.4531 11.9453 15.4531 12.1016C15.4922 12.4922 15.9609 12.6875 16.2734 12.4141C16.3125 12.375 16.3516 12.3359 16.3906 12.2969L19.4766 9.17188C21.4688 7.21875 21.4688 3.97656 19.4766 2.02344C17.5234 0.03125 14.2812 0.03125 12.3281 2.02344L9.20312 5.10938C7.25 7.0625 7.25 10.3047 9.20312 12.2969Z"
                    fill="white"
                  />
                </svg>
              </button>
            </li>
          </ul>
          <span className={styles.copiedTooltip} ref={tooltip}>
            <p>
              <strong>
                {intl.formatMessage({ id: "footer-link-pasted" })}
              </strong>{" "}
              {intl.formatMessage({ id: "footer-link-pasted-2" })}
            </p>
            <button onClick={() => handleClickNotice()}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5625 12L17.6875 8.875C18.0938 8.5 18.0938 7.875 17.6875 7.5L17 6.8125C16.625 6.40625 16 6.40625 15.625 6.8125L12.5 9.9375L9.34375 6.8125C8.96875 6.40625 8.34375 6.40625 7.96875 6.8125L7.28125 7.5C6.875 7.875 6.875 8.5 7.28125 8.875L10.4062 12L7.28125 15.1562C6.875 15.5312 6.875 16.1562 7.28125 16.5312L7.96875 17.2188C8.34375 17.625 8.96875 17.625 9.34375 17.2188L12.5 14.0938L15.625 17.2188C16 17.625 16.625 17.625 17 17.2188L17.6875 16.5312C18.0938 16.1562 18.0938 15.5312 17.6875 15.1562L14.5625 12Z"
                  fill="white"
                />
              </svg>
            </button>
          </span>
        </div>
        <div className={styles.endFooter}>
          {intl.locale === "fr" && (
            <>
              <div className={styles.replay}>
                <a
                  href="https://content-summit-2021.playplay.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <PlayCircle />
                  Replay de l'édition 2021
                </a>
              </div>
              <div className={styles.replay}>
                <a href="/fr/replay2022" target="_blank">
                  <PlayCircle />
                  Replay de l'édition 2022
                </a>
              </div>
            </>
          )}
          {intl.locale === "en" && (
            <div className={styles.otherLanguage}>
              <a href={intl.formatMessage({ id: "other-language-url" })}>
                <img src={languageLogo} alt="Language Logo" />
                {intl.formatMessage({ id: "other-language-text" })}
              </a>
            </div>
          )}
        </div>
        <div className={styles.bottom_wrapper}>
          <p>
            <span>{intl.formatMessage({ id: "footer-rights" })}</span>
            <a
              href={intl.formatMessage({ id: "footer-legals-link" })}
              target="_blank"
              rel="noreferrer"
            >
              {intl.formatMessage({ id: "footer-legals" })}
            </a>
            <a
              href={intl.formatMessage({ id: "footer-cgu-link" })}
              target="_blank"
              rel="noreferrer"
            >
              {intl.formatMessage({ id: "footer-cgu" })}
            </a>
            <a
              href={intl.formatMessage({ id: "footer-confidentiality-link" })}
              target="_blank"
              rel="noreferrer"
            >
              {intl.formatMessage({ id: "footer-confidentiality" })}
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer
