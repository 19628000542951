import PropTypes from "prop-types"
import React from "react"

import * as styles from "./Loader.module.scss"

const Loader = ({ loader }) => {
  return <div className={styles.LoaderComponent} ref={loader}></div>
}

export default Loader

Loader.propTypes = {
  loader: PropTypes.object,
}

Loader.defaultProps = {}
