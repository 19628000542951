import PropTypes from "prop-types"
import React from "react"
import { Helmet } from "react-helmet"

const Seo = ({ title, desc, ogImage }) => {
  const metaTitle = title
  const metaDesc = desc
  const metaImage = ogImage
  const metaAuthor = "Playplay"

  return (
    <Helmet
      title={metaTitle}
      titleTemplate={
        metaTitle
          ? `${metaTitle}`
          : `Content Summit 2022 | Le rendez-vous 100% digital du content marketing`
      }
      meta={[
        {
          name: `description`,
          content: metaDesc,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDesc,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          name: `image`,
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDesc,
        },
        {
          name: `twitter:creator`,
          content: metaAuthor,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
      ]}
    />
  )
}

export default Seo

Seo.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
}
