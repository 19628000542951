// extracted by mini-css-extract-plugin
export var FooterComponent = "Footer-module--FooterComponent--5sUCb";
export var wrapper = "Footer-module--wrapper--RlZJ5";
export var logo_wrapper = "Footer-module--logo_wrapper--Na8di";
export var description_wrapper = "Footer-module--description_wrapper---KtG1";
export var share_wrapper = "Footer-module--share_wrapper--jBlAr";
export var copiedTooltip = "Footer-module--copiedTooltip--WGHHP";
export var endFooter = "Footer-module--endFooter---lUC5";
export var otherLanguage = "Footer-module--otherLanguage--x2zyF";
export var bottom_wrapper = "Footer-module--bottom_wrapper--Q6Yin";
export var replay = "Footer-module--replay--Ure6d";